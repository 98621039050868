const branchOfServices = [
  { id: '2', code: '2', name: 'USA' },
  { id: '5', code: '5', name: 'USN' },
  { id: '6', code: '6', name: 'USAF' },
  { id: '4', code: '4', name: 'USMC' },
  { id: '3', code: '3', name: 'USCG' },
  { id: '1', code: '1', name: 'USSF' },
  { id: '8', code: '8', name: 'Current Federal Employee' },
  { id: '9', code: '9', name: 'Sponsored Spouse' },
  { id: '7', code: '7', name: 'National Guard/Reserve' }
];

export default branchOfServices;
