const ranks = [
  { branch: '2', code: 'PVT', name: 'PVT (USA)' },
  { branch: '2', code: 'PV2', name: 'PV2 (USA)' },
  { branch: '2', code: 'PFC', name: 'PFC (USA)' },
  { branch: '2', code: 'SPC', name: 'SPC (USA)' },
  { branch: '2', code: 'CPL', name: 'CPL (USA)' },
  { branch: '2', code: 'SGT', name: 'SGT (USA)' },
  { branch: '2', code: 'SSG', name: 'SSG (USA)' },
  { branch: '2', code: 'SFC', name: 'SFC (USA)' },
  { branch: '2', code: 'MSG', name: 'MSG (USA)' },
  { branch: '2', code: '1SG', name: '1SG (USA)' },
  { branch: '2', code: 'SGM', name: 'SGM (USA)' },
  { branch: '2', code: 'CSM', name: 'CSM (USA)' },
  { branch: '2', code: 'WO1', name: 'WO1 (USA)' },
  { branch: '2', code: 'CW2', name: 'CW2 (USA)' },
  { branch: '2', code: 'CW3', name: 'CW3 (USA)' },
  { branch: '2', code: 'CW4', name: 'CW4 (USA)' },
  { branch: '2', code: 'CW5', name: 'CW5 (USA)' },
  { branch: '2', code: '2LT', name: '2LT (USA)' },
  { branch: '2', code: '1LT', name: '1LT (USA)' },
  { branch: '2', code: 'CPT', name: 'CPT (USA)' },
  { branch: '2', code: 'MAJ', name: 'MAJ (USA)' },
  { branch: '2', code: 'LTC', name: 'LTC (USA)' },
  { branch: '2', code: 'COL', name: 'COL (USA)' },
  { branch: '2', code: 'BG', name: 'BG (USA)' },
  { branch: '2', code: 'MG', name: 'MG (USA)' },
  { branch: '2', code: 'LTG', name: 'LTG (USA)' },
  { branch: '2', code: 'GEN', name: 'GEN (USA)' },
  { branch: '2', code: 'MR', name: 'Mr. (USA)' },
  { branch: '2', code: 'MRS', name: 'Mrs. (USA)' },
  { branch: '2', code: 'MS', name: 'Ms. (USA)' },
  { branch: '2', code: 'DR', name: 'Dr. (USA)' },
  { branch: '3', code: 'SN', name: 'SN (USCG)' },
  { branch: '3', code: 'PO3', name: 'PO3 (USCG)' },
  { branch: '3', code: 'PO2', name: 'PO2 (USCG)' },
  { branch: '3', code: 'PO1', name: 'PO1 (USCG)' },
  { branch: '3', code: 'CPO', name: 'CPO (USCG)' },
  { branch: '3', code: 'SCPO', name: 'SCPO (USCG)' },
  { branch: '3', code: 'MCPO', name: 'MCPO (USCG)' },
  { branch: '3', code: 'CMC', name: 'CMC (USCG)' },
  { branch: '3', code: 'WO1', name: 'WO1 (USCG)' },
  { branch: '3', code: 'CWO2', name: 'CWO2 (USCG)' },
  { branch: '3', code: 'CWO3', name: 'CWO3 (USCG)' },
  { branch: '3', code: 'CWO4', name: 'CWO4 (USCG)' },
  { branch: '3', code: 'ENS', name: 'ENS (USCG)' },
  { branch: '3', code: 'LTJG', name: 'LTJG (USCG)' },
  { branch: '3', code: 'LT', name: 'LT (USCG)' },
  { branch: '3', code: 'LCDR', name: 'LCDR (USCG)' },
  { branch: '3', code: 'CDR', name: 'CDR (USCG)' },
  { branch: '3', code: 'CAPT', name: 'CAPT (USCG)' },
  { branch: '3', code: 'RDML', name: 'RDML (USCG)' },
  { branch: '3', code: 'RADM', name: 'RADM (USCG)' },
  { branch: '3', code: 'VADM', name: 'VADM (USCG)' },
  { branch: '3', code: 'ADM', name: 'ADM (USCG)' },
  { branch: '3', code: 'MR', name: 'Mr. (USCG)' },
  { branch: '3', code: 'MRS', name: 'Mrs. (USCG)' },
  { branch: '3', code: 'MS', name: 'Ms. (USCG)' },
  { branch: '3', code: 'DR', name: 'Dr. (USCG)' },
  { branch: '4', code: 'PVT', name: 'PVT (USMC)' },
  { branch: '4', code: 'PFC', name: 'PFC (USMC)' },
  { branch: '4', code: 'LCPL', name: 'LCPL (USMC)' },
  { branch: '4', code: 'CPL', name: 'CPL (USMC)' },
  { branch: '4', code: 'SGT', name: 'SGT (USMC)' },
  { branch: '4', code: 'SSGT', name: 'SSGT (USMC)' },
  { branch: '4', code: 'GYSGT', name: 'GYSGT (USMC)' },
  { branch: '4', code: '1STSGT', name: '1STSGT (USMC)' },
  { branch: '4', code: 'MSGT', name: 'MSGT (USMC)' },
  { branch: '4', code: 'MGYSGT', name: 'MGYSGT (USMC)' },
  { branch: '4', code: 'SGTMAJ', name: 'SGTMAJ (USMC)' },
  { branch: '4', code: 'WO', name: 'WO (USMC)' },
  { branch: '4', code: 'CWO2', name: 'CWO2 (USMC)' },
  { branch: '4', code: 'CWO3', name: 'CWO3 (USMC)' },
  { branch: '4', code: 'CWO4', name: 'CWO4 (USMC)' },
  { branch: '4', code: 'CWO5', name: 'CWO5 (USMC)' },
  { branch: '4', code: '2NDLT', name: '2NDLT (USMC)' },
  { branch: '4', code: '1STLT', name: '1STLT (USMC)' },
  { branch: '4', code: 'CAPT', name: 'CAPT (USMC)' },
  { branch: '4', code: 'MAJ', name: 'MAJ (USMC)' },
  { branch: '4', code: 'LTCOL', name: 'LTCOL (USMC)' },
  { branch: '4', code: 'COL', name: 'COL (USMC)' },
  { branch: '4', code: 'BGEN', name: 'BGEN (USMC)' },
  { branch: '4', code: 'MAJGEN', name: 'MAJGEN (USMC)' },
  { branch: '4', code: 'LTGEN', name: 'LTGEN (USMC)' },
  { branch: '4', code: 'GEN', name: 'GEN (USMC)' },
  { branch: '4', code: 'MR', name: 'Mr. (USMC)' },
  { branch: '4', code: 'MRS', name: 'Mrs. (USMC)' },
  { branch: '4', code: 'MS', name: 'Ms. (USMC)' },
  { branch: '4', code: 'DR', name: 'Dr. (USMC)' },
  { branch: '5', code: 'SR', name: 'SR (USN)' },
  { branch: '5', code: 'SA', name: 'SA (USN)' },
  { branch: '5', code: 'SN', name: 'SN (USN)' },
  { branch: '5', code: 'FR', name: 'FR (USN)' },
  { branch: '5', code: 'FA', name: 'FA (USN)' },
  { branch: '5', code: 'FN', name: 'FN (USN)' },
  { branch: '5', code: 'AR', name: 'AR (USN)' },
  { branch: '5', code: 'AA', name: 'AA (USN)' },
  { branch: '5', code: 'AN', name: 'AN (USN)' },
  { branch: '5', code: 'CR', name: 'CR (USN)' },
  { branch: '5', code: 'CA', name: 'CA (USN)' },
  { branch: '5', code: 'CN', name: 'CN (USN)' },
  { branch: '5', code: 'PO3', name: 'PO3 (USN)' },
  { branch: '5', code: 'PO2', name: 'PO2 (USN)' },
  { branch: '5', code: 'PO1', name: 'PO1 (USN)' },
  { branch: '5', code: 'CPO', name: 'CPO (USN)' },
  { branch: '5', code: 'SCPO', name: 'SCPO (USN)' },
  { branch: '5', code: 'MCPO', name: 'MCPO (USN)' },
  { branch: '5', code: 'WO', name: 'WO (USN)' },
  { branch: '5', code: 'CWO2', name: 'CWO2 (USN)' },
  { branch: '5', code: 'CWO3', name: 'CWO3 (USN)' },
  { branch: '5', code: 'CWO4', name: 'CWO4 (USN)' },
  { branch: '5', code: 'CWO5', name: 'CWO5 (USN)' },
  { branch: '5', code: 'ENS', name: 'ENS (USN)' },
  { branch: '5', code: 'LTJG', name: 'LTJG (USN)' },
  { branch: '5', code: 'LT', name: 'LT (USN)' },
  { branch: '5', code: 'LCDR', name: 'LCDR (USN)' },
  { branch: '5', code: 'CDR', name: 'CDR (USN)' },
  { branch: '5', code: 'CAPT', name: 'CAPT (USN)' },
  { branch: '5', code: 'RDML', name: 'RDML (USN)' },
  { branch: '5', code: 'RADM', name: 'RADM (USN)' },
  { branch: '5', code: 'VADM', name: 'VADM (USN)' },
  { branch: '5', code: 'ADM', name: 'ADM (USN)' },
  { branch: '5', code: 'MR', name: 'Mr. (USN)' },
  { branch: '5', code: 'MRS', name: 'Mrs. (USN)' },
  { branch: '5', code: 'MS', name: 'Ms. (USN)' },
  { branch: '5', code: 'DR', name: 'Dr. (USN)' },
  { branch: '6', code: 'AB', name: 'AB (USAF)' },
  { branch: '6', code: 'AMN', name: 'Amn (USAF)' },
  { branch: '6', code: 'A1C', name: 'A1C (USAF)' },
  { branch: '6', code: 'SRA', name: 'SrA (USAF)' },
  { branch: '6', code: 'SSGT', name: 'SSgt (USAF)' },
  { branch: '6', code: 'TSGT', name: 'TSgt (USAF)' },
  { branch: '6', code: 'MSGT', name: 'MSgt (USAF)' },
  { branch: '6', code: 'SMSGT', name: 'SMSgt (USAF)' },
  { branch: '6', code: 'CMSGT', name: 'CMSgt (USAF)' },
  { branch: '6', code: '2LT', name: '2nd Lt (USAF)' },
  { branch: '6', code: '1LT', name: '1st Lt (USAF)' },
  { branch: '6', code: 'CAPT', name: 'Capt (USAF)' },
  { branch: '6', code: 'MAJ', name: 'Maj (USAF)' },
  { branch: '6', code: 'LTCOL', name: 'Lt Col (USAF)' },
  { branch: '6', code: 'COL', name: 'Col (USAF)' },
  { branch: '6', code: 'BRIGGEN', name: 'Brig Gen (USAF)' },
  { branch: '6', code: 'MAJGEN', name: 'Maj Gen (USAF)' },
  { branch: '6', code: 'LTGEN', name: 'Lt Gen (USAF)' },
  { branch: '6', code: 'GEN', name: 'Gen (USAF)' },
  { branch: '6', code: 'MR', name: 'Mr. (USAF)' },
  { branch: '6', code: 'MRS', name: 'Mrs. (USAF)' },
  { branch: '6', code: 'MS', name: 'Ms. (USAF)' },
  // { branch: '7', code: '1LT', name: 'Lieutenant' },
  // { branch: '7', code: '1SG', name: 'First Sergeant' },
  // { branch: '7', code: '1STLT', name: 'Lieutenant' },
  // { branch: '7', code: '1STSGT', name: 'First Sergeant' },
  // { branch: '7', code: '2LT', name: 'Lieutenant' },
  // { branch: '7', code: '2NDLT', name: 'Lieutenant' },
  // { branch: '7', code: 'A1C', name: 'Airman' },
  // { branch: '7', code: 'AB', name: 'Airman' },
  // { branch: '7', code: 'ADM', name: 'Admiral' },
  // { branch: '7', code: 'AMN', name: 'Airman' },
  // { branch: '7', code: 'BG', name: 'General' },
  // { branch: '7', code: 'BGEN', name: 'General' },
  // { branch: '7', code: 'BRIGGEN', name: 'General' },
  // { branch: '7', code: 'CAPT', name: 'Captain' },
  // { branch: '7', code: 'CDR', name: 'Commander' },
  // { branch: '7', code: 'CMSGT', name: 'Chief Master Sergeant' },
  // { branch: '7', code: 'COL', name: 'Colonel' },
  // { branch: '7', code: 'CPL', name: 'Corporal' },
  // { branch: '7', code: 'CPO', name: 'Chief' },
  // { branch: '7', code: 'CPT', name: 'Captain' },
  // { branch: '7', code: 'CSM', name: 'Sergeant Major' },
  // { branch: '7', code: 'CWO2', name: 'Chief Warrant Officer' },
  // { branch: '7', code: 'CWO3', name: 'Chief Warrant Officer' },
  // { branch: '7', code: 'CWO4', name: 'Chief Warrant Officer' },
  // { branch: '7', code: 'CWO5', name: 'Chief Warrant Officer' },
  // { branch: '7', code: 'DR', name: 'Dr' },
  // { branch: '7', code: 'ENS', name: 'Ensign' },
  // { branch: '7', code: 'FIREMAN', name: 'Fireman' },
  // { branch: '7', code: 'GEN', name: 'General' },
  // { branch: '7', code: 'GYSGT', name: 'Gunnery Sergeant' },
  // { branch: '7', code: 'LCDR', name: 'Lieutenant Commander' },
  // { branch: '7', code: 'LCPL', name: 'Lance Corporal' },
  // { branch: '7', code: 'LT', name: 'Lieutenant' },
  // { branch: '7', code: 'LTC', name: 'Lieutenant Colonel' },
  // { branch: '7', code: 'LTCOL', name: 'Lieutenant Colonel' },
  // { branch: '7', code: 'MAJ', name: 'Major' },
  // { branch: '7', code: 'MAJGEN', name: 'General' },
  // { branch: '7', code: 'MCPO', name: 'Master Chief' },
  // { branch: '7', code: 'MG', name: 'General' },
  // { branch: '7', code: 'MGYSGT', name: 'Master Gunnery Sergeant' },
  // { branch: '7', code: 'MR', name: 'Mr' },
  // { branch: '7', code: 'MRS', name: 'Mrs' },
  // { branch: '7', code: 'MS', name: 'Ms' },
  // { branch: '7', code: 'MSG', name: 'Master Sergeant' },
  // { branch: '7', code: 'MSGT', name: 'Master Sergeant' },
  // { branch: '7', code: 'PFC', name: 'Private First Class' },
  // { branch: '7', code: 'PO1', name: 'Petty Officer' },
  // { branch: '7', code: 'PO2', name: 'Petty Officer' },
  // { branch: '7', code: 'PO3', name: 'Petty Officer' },
  // { branch: '7', code: 'PVT', name: 'Private' },
  // { branch: '7', code: 'RADM', name: 'Admiral' },
  // { branch: '7', code: 'SA', name: 'Seaman Apprentice' },
  // { branch: '7', code: 'SCPO', name: 'Senior Chief' },
  // { branch: '7', code: 'SEAMAN', name: 'Seaman' },
  // { branch: '7', code: 'SFC', name: 'Sergeant First Class' },
  // { branch: '7', code: 'SGM', name: 'Sergeant Major' },
  // { branch: '7', code: 'SGT', name: 'Sergeant' },
  // { branch: '7', code: 'SGTMAJ', name: 'Sergeant Major' },
  // { branch: '7', code: 'SMSGT', name: 'Senior Master Sergeant' },
  // { branch: '7', code: 'SPC', name: 'Specialist' },
  // { branch: '7', code: 'SR', name: 'Seaman Recruit' },
  // { branch: '7', code: 'SRA', name: 'Senior Airman' },
  // { branch: '7', code: 'SSG', name: 'Staff Sergeant' },
  // { branch: '7', code: 'SSGT', name: 'Staff Sergeant' },
  // { branch: '7', code: 'TSGT', name: 'Technical Sergeant' },
  // { branch: '7', code: 'VADM', name: 'Admiral' },
  // { branch: '7', code: 'WO', name: 'Warrant Officer' },
  { branch: '7', code: 'MR', name: 'Mr' },
  { branch: '7', code: 'MRS', name: 'Mrs' },
  { branch: '7', code: 'MS', name: 'Ms' },
  { branch: '8', code: 'DR', name: 'Dr. (SES)' },
  { branch: '8', code: 'MR', name: 'Mr. (SES)' },
  { branch: '8', code: 'MRS', name: 'Mrs. (SES)' },
  { branch: '8', code: 'MS', name: 'Ms. (SES)' },
  { branch: '9', code: 'DR', name: 'Dr' },
  { branch: '9', code: 'MR', name: 'Mr' },
  { branch: '9', code: 'MRS', name: 'Mrs' },
  { branch: '9', code: 'MS', name: 'Ms' },
  { branch: '1', code: 'Spc1', name: 'Specialist 1 (USSF)' },
  { branch: '1', code: 'Spc2', name: 'Specialist 2 (USSF)' },
  { branch: '1', code: 'Spc3', name: 'Specialist 3 (USSF)' },
  { branch: '1', code: 'Spc4', name: 'Specialist 4 (USSF)' },
  { branch: '1', code: 'SSgt', name: 'Sergeant (USSF)' },
  { branch: '1', code: 'TSgt', name: 'Technical Sergeant (USSF)' },
  { branch: '1', code: 'MSgt', name: 'Master Sergeant (USSF)' },
  { branch: '1', code: 'SMSgt', name: 'Senior Master Sergeant (USSF)' },
  { branch: '1', code: 'CMSgt', name: 'Chief Master Sergeant (USSF)' },
  {
    branch: '1',
    code: 'CMSAF',
    name: 'Chief Master Sergeant of the Space Force (USSF)'
  },
  { branch: '1', code: '2ndLt', name: 'Second Lieutenant (USSF)' },
  { branch: '1', code: '1stLt', name: 'First Lieutenant (USSF)' },
  { branch: '1', code: 'Capt', name: 'Captain (USSF)' },
  { branch: '1', code: 'Maj', name: 'Major (USSF)' },
  { branch: '1', code: 'LtCol', name: 'Lieutenant Colonel (USSF)' },
  { branch: '1', code: 'Col', name: 'Colonel (USSF)' },
  { branch: '1', code: 'BrigGen', name: 'Brigadier General (USSF)' },
  { branch: '1', code: 'MajGen', name: 'Major General (USSF)' },
  { branch: '1', code: 'LtGen', name: 'Lieutenant General (USSF)' },
  { branch: '1', code: 'Gen', name: 'General (USSF)' }
];

export default ranks;
