import { connectRouter } from 'connected-react-router';
import { Durations } from 'helpers/constants';
import localforage from 'localforage';
import _concat from 'lodash/concat';
import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import createFilter from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import agentUrls from 'store/parts/agent-urls/reducers';
import alert from 'store/parts/alert/reducers';
import applications from 'store/parts/applications/reducers';
import applyInsuranceForm from 'store/parts/apply-insurance-form/reducers';
import authModal from 'store/parts/auth-modal/reducers';
import ballot from 'store/parts/ballot/reducers';
import contactUsForm from 'store/parts/contact-us-form/reducers';
import digitalSignup from 'store/parts/digital-signup/reducers';
import downloadForm from 'store/parts/download-form/reducers';
import eSeminars from 'store/parts/e-seminars/reducers';
import electronicConsent from 'store/parts/electronic-consent/reducers';
import email from 'store/parts/email/reducers';
import financialArticlesItems from 'store/parts/financial-articles-items/reducers';
import financialArticles from 'store/parts/financial-articles/reducers';
import financialVideos from 'store/parts/financial-videos/reducers';
import finishedApplications from 'store/parts/finished-applications/reducers';
import getEducatedItems from 'store/parts/get-educated-items/reducers';
import getEducated from 'store/parts/get-educated/reducers';
import lifeEventsSections from 'store/parts/life-events-sections/reducers';
import lifeEvents from 'store/parts/life-events/reducers';
import liveCalculator from 'store/parts/live-calculator/reducers';
import main from 'store/parts/main/reducers';
import militaryResourcesSections from 'store/parts/military-resources-sections/reducers';
import militaryResources from 'store/parts/military-resources/reducers';
import outreachItems from 'store/parts/outreach-items/reducers';
import outreaches from 'store/parts/outreaches/reducers';
import paymentPurchase from 'store/parts/payment-purchase/reducers';
import purls from 'store/parts/purls/reducers';
import search from 'store/parts/search/reducers';
import seo from 'store/parts/seo/reducers';
import signupModal from 'store/parts/signup-modal/reducers';
import updateBene from 'store/parts/update-bene/reducers';
import updatePaymentMethod from 'store/parts/update-payment-method/reducers';
import user from 'store/parts/user/reducers';

import migrations from './migrations';

const reducers = [
  { key: 'alert', reducer: alert.reducer },
  { key: 'agentUrls', reducer: agentUrls.reducer },
  {
    key: 'applications',
    reducer: applications.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(applications),
        {
          id: 'expire',
          config: {
            expireSeconds: 30 * Durations.secondsInDay,
            autoExpire: true,
            expiredState: applications.initialState
          }
        }
      ]
    }
  },
  { key: 'applyInsuranceForm', reducer: applyInsuranceForm.reducer },
  { key: 'authModal', reducer: authModal.reducer },
  {
    key: 'ballot',
    reducer: ballot.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(ballot),
        {
          id: 'expire',
          config: {
            expireSeconds: Durations.secondsInDay,
            autoExpire: true,
            expiredState: ballot.initialState
          }
        }
      ]
    }
  },
  { key: 'contactUsForm', reducer: contactUsForm.reducer },
  { key: 'digitalSignup', reducer: digitalSignup.reducer },
  { key: 'downloadForm', reducer: downloadForm.reducer },
  {
    key: 'eSeminars',
    reducer: eSeminars.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(eSeminars),
        getDefaultExpireTransform(electronicConsent)
      ]
    }
  },
  {
    key: 'electronicConsent',
    reducer: electronicConsent.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(electronicConsent),
        {
          id: 'expire',
          config: {
            expireSeconds: 365 * Durations.secondsInDay,
            autoExpire: true,
            expiredState: electronicConsent.initialState
          }
        }
      ]
    }
  },
  {
    key: 'email',
    reducer: email.reducer
  },
  {
    key: 'finishedApplications',
    reducer: finishedApplications.reducer
  },
  {
    key: 'lifeEvents',
    reducer: lifeEvents.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(lifeEvents),
        getDefaultExpireTransform(lifeEvents)
      ]
    }
  },
  {
    key: 'lifeEventsSections',
    reducer: lifeEventsSections.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(lifeEventsSections),
        getDefaultExpireTransform(lifeEventsSections)
      ]
    }
  },
  {
    key: 'main',
    reducer: main.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(main),
        {
          id: 'expire',
          config: {
            expireSeconds: 365 * Durations.secondsInDay,
            autoExpire: true,
            expiredState: main.initialState
          }
        }
      ]
    }
  },
  {
    key: 'militaryResources',
    reducer: militaryResources.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(militaryResources),
        getDefaultExpireTransform(militaryResources)
      ]
    }
  },
  {
    key: 'militaryResourcesSections',
    reducer: militaryResourcesSections.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(militaryResourcesSections),
        getDefaultExpireTransform(militaryResourcesSections)
      ]
    }
  },
  {
    key: 'getEducated',
    reducer: getEducated.reducer
    // persist: {
    //   transforms: [
    //     getDefaultFilterTransform(getEducated),
    //     getDefaultExpireTransform(getEducated)
    //   ]
    // }
  },
  {
    key: 'getEducatedItems',
    reducer: getEducatedItems.reducer
    // persist: {
    //   transforms: [
    //     getDefaultFilterTransform(getEducatedItems),
    //     getDefaultExpireTransform(getEducatedItems)
    //   ]
    // }
  },
  {
    key: 'outreachItems',
    reducer: outreachItems.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(outreachItems),
        getDefaultExpireTransform(outreachItems)
      ]
    }
  },
  {
    key: 'outreaches',
    reducer: outreaches.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(outreaches),
        getDefaultExpireTransform(outreaches)
      ]
    }
  },
  { key: 'paymentPurchase', reducer: paymentPurchase.reducer },
  {
    key: 'purls',
    reducer: purls.reducer
  },
  {
    key: 'search',
    reducer: search.reducer
  },
  {
    key: 'seo',
    reducer: seo.reducer
  },
  { key: 'signupModal', reducer: signupModal.reducer },
  {
    key: 'liveCalculator',
    reducer: liveCalculator.reducer,
    persist: {
      transforms: [
        getDefaultFilterTransform(liveCalculator),
        {
          id: 'expire',
          config: {
            expireSeconds: 7 * Durations.secondsInDay,
            autoExpire: true,
            expiredState: liveCalculator.initialState
          }
        }
      ]
    }
  },
  { key: 'updateBene', reducer: updateBene.reducer },
  { key: 'updatePaymentMethod', reducer: updatePaymentMethod.reducer },
  { key: 'user', reducer: user.reducer },
  {
    key: 'financialVideos',
    reducer: financialVideos.reducer
  },
  {
    key: 'financialArticles',
    reducer: financialArticles.reducer
  },
  {
    key: 'financialArticlesItems',
    reducer: financialArticlesItems.reducer
  }
];

export function getDefaultFilterTransform(reducerConfig) {
  return { id: 'filter', config: reducerConfig.filter };
}

export function getDefaultExpireTransform(reducerConfig) {
  return {
    id: 'expire',
    config: {
      expireSeconds:
        process.env.REACT_APP_SERVER_ENV === 'preview'
          ? 1
          : Durations.secondsInMinute * 10,
      autoExpire: true,
      expiredState: reducerConfig.initialState
    }
  };
}

export function getReducersObj(config) {
  return config.reduce((memo, { key, reducer }) => {
    memo[key] = reducer;
    return memo;
  }, {});
}

export function getPersistedReducersList(config) {
  return config.reduce((memo, { key, persist }) => {
    if (persist) {
      memo.push(key);
    }
    return memo;
  }, []);
}

function getTransforms(config) {
  return config.reduce((memo, { key, persist }) => {
    if (persist && persist.transforms) {
      const reducerTransforms = persist.transforms.reduce(
        (memo, { id, config }) => {
          let transformCb;
          switch (id) {
            case 'filter':
              // https://github.com/edy/redux-persist-transform-filter
              transformCb = createFilter;
              break;
            case 'expire':
              // https://github.com/kamranahmedse/redux-persist-expire
              transformCb = expireReducer;
              break;
            default:
              break;
          }
          if (typeof transformCb === 'function') {
            memo.push(transformCb(key, config));
          }
          return memo;
        },
        []
      );
      memo = _concat(memo, reducerTransforms);
    }
    return memo;
  }, []);
}

export default function getRootReducer(history) {
  const primaryPersistConfig = {
    version: 1,
    key: 'primary',
    storage: localforage,
    whitelist: getPersistedReducersList(reducers),
    transforms: getTransforms(reducers),
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations, {
      debug: process.env.REACT_APP_SERVER_ENV !== 'production'
    })
  };
  return persistReducer(
    primaryPersistConfig,
    combineReducers({
      router: connectRouter(history),
      ...(getReducersObj(reducers) || {})
    })
  );
}
