import { BRAND } from 'config/core';
import { getPageLink } from 'helpers/resources';

const nationalGuardConfig = {
  common: {
    brand: BRAND.ng,
    schedule: 'NGA',
    type: 'N3',
    minAge: 18,
    maxAge: 69,
    pdf: '',
    notAvailableForStates: [
      'AA',
      'AE',
      'AP',
      'AL',
      'AK',
      'AZ',
      'AR',
      'AS',
      'CA',
      'CO',
      'DE',
      'DC',
      'FL',
      'GA',
      'GU',
      'HI',
      'ID',
      'IN',
      'KS',
      'KY',
      'LA',
      'MD',
      'MI',
      'MS',
      'MO',
      'MP',
      'MT',
      'NV',
      'NJ',
      'NM',
      'NC',
      'OH',
      'OR',
      'PA',
      'PR',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VI',
      'WA',
      'WV',
      'WI'
    ],
    paymentMethods: ['ngallotment', 'ngdirect', 'ngbill'],
    coverageAmount: {
      min: 100000,
      max: 500000,
      step: 50000
    },
    spouseCoverageAmount: {
      min: 50000,
      max: 500000,
      step: 50000
    },
    dependendCoverageAmount: {
      min: 10000,
      max: 20000,
      step: 10000
    }
  },
  'national-guard': {
    shortName: 'National Guard Online Application',
    appLink: getPageLink('page-national-guard-app')
  },
  'national-guard-member-quick-step': {
    shortName: 'Quick Step National Guard Application',
    appLink: getPageLink('page-national-guard-member-quick-step-app'),
    fullAppFormId: {
      member: 'national-guard',
      spouse: 'national-guard-spouse'
    },
    memberStatuses: ['N', 'A', 'C'],
    branches: ['7'],
    maxAge: 69,
    ageToCoverage: [
      {
        maxAge: 49,
        maxCoverage: 250000
      },
      {
        minAge: 50,
        maxAge: 59,
        maxCoverage: 100000
      },
      {
        minAge: 60,
        maxAge: 68,
        maxCoverage: 0
      }
    ]
  },
  'national-guard-maine': {
    shortName: 'National Guard Maine Online Application',
    appLink: getPageLink('page-national-guard-maine-app'),
    availableForStates: ['ME']
  },
  'national-guard-maine-quick-step': {
    shortName: 'Quick Step National Guard Maine Application',
    appLink: getPageLink('page-national-guard-maine-quick-step-app'),
    availableForStates: ['ME'],
    fullAppFormId: {
      member: 'national-guard-maine'
    },
    memberStatuses: ['N', 'A', 'C'],
    branches: ['7'],
    maxAge: 69,
    ageToCoverage: [
      {
        maxAge: 49,
        maxCoverage: 250000
      },
      {
        minAge: 50,
        maxAge: 59,
        maxCoverage: 100000
      },
      {
        minAge: 60,
        maxAge: 68,
        maxCoverage: 0
      }
    ]
  },
  'national-guard-spouse': {
    shortName: 'Sponsored Spouse National Guard Online Application',
    appLink: getPageLink('page-national-guard-spouse-app'),
    memberStatuses: ['N1', 'N-1', 'A3', 'C1']
  },
  'national-guard-quick-step': {
    shortName: 'Quick Step National Guard Spouse Application',
    appLink: getPageLink('page-national-guard-quick-step-app'),
    fullAppFormId: {
      member: 'national-guard',
      spouse: 'national-guard-spouse'
    },
    memberStatuses: ['NS', 'NS-', 'AS', 'CS'],
    branches: ['7'],
    maxAge: 69,
    ageToCoverage: [
      {
        maxAge: 49,
        maxCoverage: 250000
      },
      {
        minAge: 50,
        maxAge: 59,
        maxCoverage: 100000
      },
      {
        minAge: 60,
        maxAge: 68,
        maxCoverage: 0
      }
    ]
  }
};

export default nationalGuardConfig;
